import React from "react";
import { string } from "prop-types";
import {
  Wrapper,
  FooterWrapper,
  LogoLinkWrapper,
  LogoWrapper,
  NetworksWrapper,
  NetworksLinkWrapper,
  Spacer,
  HorairesWrapper,
  HorairesTextWrapper,
  AideWrapper,
  CopyrightWrapper,
  LinkWrapper,
  PaddingMobile,
  FollowWrap,
  LineWrapper,
  IconWrapper,
  Icon,
  Addresses,
  FooterResp,
  ThirdResp,
  FourthResp
} from "./Footer.module.css";
import instagram from "./../../../../res/icons/Instagram.png";
import facebook from "./../../../../res/icons/Facebook.png";
import twitter from "./../../../../res/icons/Twitter.png";
import youtube from "./../../../../res/icons/Youtube.png";
import artsy from  "./../../../../res/icons/artsy.png";
import { classNames } from "./../../../../helpers/classNames";
import {
  PaddingRight20,
} from "./../../../../css/Padding.module.css";
const Footer = ({ className, ...props }) => {
  return (
    <div className={Wrapper}>
      <div className={FooterWrapper}>
        <div>
          <a className={LogoLinkWrapper} href="/fr/">
            <h2 className={LogoWrapper}>GHOST GALERIE</h2>
          </a>
          <h4 className={FollowWrap}>Follow Us</h4>
          <div className={NetworksWrapper}>
            <a
              className={classNames([NetworksLinkWrapper, PaddingRight20])}
              href="https://www.instagram.com/ghostgalerie/?hl=fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={Icon} src={instagram} alt="Nothing"></img>
            </a>
            <a
              className={classNames([NetworksLinkWrapper, PaddingRight20])}
              href="https://www.facebook.com/ghostgalerie/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={Icon} src={facebook} alt="Nothing"></img>
            </a>
            <a
              className={classNames([NetworksLinkWrapper, PaddingRight20])}
              href="https://twitter.com/caroline_pdb"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={Icon} src={twitter} alt="Nothing"></img>
            </a>
            <a
             className={classNames([NetworksLinkWrapper, PaddingRight20])}
              href="https://www.youtube.com/channel/UClDFw_2UzfrkBDcU16H0Gbg/playlists?view_as=subscriber"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={Icon} style={{}} src={youtube} alt="Nothing" ></img>
            </a>
            <a
              className={NetworksLinkWrapper}
              href="https://www.artsy.net/partner/ghost-galerie"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img className={Icon} style={{}} src={artsy} alt="Nothing" ></img>
            </a>
          </div>
        </div>
        <div className={Spacer}></div>
        <div className={FooterResp}>
          <div >

        <div className={classNames([HorairesWrapper, PaddingMobile])}>
          <h3>Informations</h3>
          <a className={LinkWrapper} href="/fr/artistes">
            Artistes
          </a>
          <a className={LinkWrapper} href="/fr/expositions">
            Expositions
          </a>
          {/* <a className={LinkWrapper} href="/fr/shop">
            Shop
          </a> */}
          {/* <a className={LinkWrapper} href="/fr/visite-virtuelle">
            Visite virtuelle
          </a> */}
          <a className={LinkWrapper} href="/fr/a-propos">
            À Propos
          </a>
          
        </div>
        <div className={classNames([AideWrapper, PaddingMobile, ThirdResp])}>
          <h3>Aide</h3>
          <a  className={LinkWrapper} href="/fr/contactez-nous">
            Contact
          </a>
          <a  className={LinkWrapper} href="/fr/mentions-legales">
           Mentions Légales
          </a>
        </div>
          </div>
        <div className={classNames([HorairesWrapper, PaddingMobile, Addresses])}>
          <h3>Adresses</h3>
          {/* <p className={HorairesTextWrapper}> */}
            <a className={LinkWrapper} target="_blank" href="https://www.google.com/maps/place/2+Rue+de+Belloi,+13006+Marseille/@43.2892859,5.3751832,17z/data=!3m1!4b1!4m5!3m4!1s0x12c9c0b74684c4e3:0xf5c978b6743c89ab!8m2!3d43.2892859!4d5.3773719">2 rue de Belloi<br/>Marseille 13006</a><br/>
            <p style={{color: "white", fontSize: "14px", paddingTop: "4px"}}>
              +33 (0)4 88 60 05 76
            </p>
            <p style={{color: "white", fontSize: "14px", paddingTop: "15px"}}>
              info@ghostgalerie.com
            </p>
            <a target="_blank" href="https://www.google.com/maps/place/11+Rue+de+Miromesnil,+75008+Paris/@48.8719848,2.3131828,17z/data=!3m1!4b1!4m6!3m5!1s0x47e66fc93339e06b:0x121e8cb2ae0fdb11!8m2!3d48.8719848!4d2.3157577!16s%2Fg%2F11b8v480w0?entry=ttu">11<b style={{fontSize:"30px"}}></b> rue de Miromesnil<br/>Paris 75008</a>
            <p style={{color: "white", fontSize: "14px", paddingTop: "4px"}}>
              +33 (0)1 85 73 27 99
            </p>
          {/* </p> */}
        </div>
        </div>

        <div className={classNames([AideWrapper, PaddingMobile, FourthResp])}>
          <h3>Aide</h3>
          <a className={LinkWrapper} href="/fr/contactez-nous">
            Contact
          </a>
          <a className={LinkWrapper} href="/fr/mentions-legales">
          Mentions Légales
          </a>
        </div>
      </div>
      <div className={CopyrightWrapper}>
        © 2022. Tous droits réservés GHOST GALERIE
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: string,
};

Footer.defaultProps = {
  className: "",
};

export default Footer;
