import React, { useState, useEffect } from "react";
import { Wrapper } from "./Layout.module.css";
import Navbar from "./Navbar";
import Footer from "./Footer";
import NavbarProjects from "../LayoutProjects/Navbar";

const Layout = ({ children }) => {
  const [width, setWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : null
  );

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className={Wrapper}>
      <Navbar width={width} />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
