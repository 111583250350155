// extracted by mini-css-extract-plugin
export var Container = "Navbar-module--Container--HtFtS";
export var DropdownImageWrapper = "Navbar-module--DropdownImageWrapper--TDb-m";
export var DropdownMenu = "Navbar-module--DropdownMenu--GBBZ4";
export var FlagImageWrapper = "Navbar-module--FlagImageWrapper--LHGpt";
export var HistoryButton = "Navbar-module--HistoryButton--d+BAd";
export var HistoryContent = "Navbar-module--HistoryContent--nFFTZ";
export var HistoryDropdown = "Navbar-module--HistoryDropdown--EDJUl";
export var NavbarWrapper = "Navbar-module--NavbarWrapper--uqaa4";
export var SecondBar = "Navbar-module--SecondBar--fxrQ1";
export var TitleWrapper = "Navbar-module--TitleWrapper--EQ5lz";
export var WhiteTextLink = "Navbar-module--WhiteTextLink--03YHZ";
export var dropbtn = "Navbar-module--dropbtn--juk8o";
export var dropdown = "Navbar-module--dropdown--L+Hga";
export var dropdownContent = "Navbar-module--dropdownContent--aea8o";