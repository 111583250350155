import React, { useState } from "react";
import {
  NavbarWrapper,
  TitleWrapper,
  WhiteTextLink,
  Container,
  DropdownImageWrapper,
  FlagImageWrapper,
  dropdown,
  dropbtn,
  dropdownContent,
  DropdownMenu,
  SecondBar,
  HistoryContent
} from "./Navbar.module.css";
import { string, number } from "prop-types";
import BurgerMenu from "../../../BurgerMenu";
import Dropdown from "./../../../../res/caret-down.png";
import FrenchFlag from "./../../../../res/france.png";
import EnglishFlag from "./../../../../res/united-kingdom.png";

const Navbar = ({ width, path }) => {
  const [displayBurger, setDisplayBurger] = useState(false);
  const [navB , setnavB] = useState(false);

  // Ligne a changé pour récuperer les bons liens
  // const base_url = "http://localhost:8000/"
  const base_url = "https://tender-liskov-bcd099.netlify.app/"

  const fr_urls = [
    // base_url + base_url + "fr",
    base_url + "fr",
    base_url + "fr/contactez-nous",
    base_url + "fr/expositions",
    base_url + "fr/shop",
    base_url + "fr/a-propos",
    base_url + "fr/mentions-legales",
    base_url + "fr/visite-virtuelle",
    base_url + "fr/artistes",
    base_url + "fr/artistes/iz-the-wiz",
    base_url + "fr/artistes/john-fekner",
    base_url + "fr/artistes/kenny-scharf",
    base_url + "fr/artistes/kool-koor",
    base_url + "fr/artistes/lady-pink",
    base_url + "fr/artistes/lee-quinones",
    base_url + "fr/artistes/niels-shoe-meulman",
    base_url + "fr/artistes/noc",
    base_url + "fr/artistes/phase-2",
    base_url + "fr/artistes/quik",
    base_url + "fr/artistes/quik/",
    base_url + "fr/artistes/rammellzee",
    base_url + "fr/artistes/richard-hambleton",
    base_url + "fr/artistes/seen",
    base_url + "fr/artistes/sharp",
    base_url + "fr/artistes/toxic",
    base_url + "fr/artistes/zephyr",
    base_url + "fr/artistes/aone",
    base_url + "fr/artistes/blade",
    base_url + "fr/artistes/crash",
    base_url + "fr/artistes/daze",
    base_url + "fr/artistes/delta-2",
    base_url + "fr/artistes/dondi-white",
    base_url + "fr/artistes/fab-5",
    base_url + "fr/artistes/futura-2000",
    base_url + "fr/artistes/bando",
    base_url + "fr/artistes/bill-blast",
    base_url + "fr/artistes/chaz-bojorquez",
    base_url + "fr/artistes/daniel-arsham",
    base_url + "fr/artistes/eddie-martinez",
    base_url + "fr/artistes/henry-chalfant",
    base_url + "fr/artistes/henry-chalfant/",
    base_url + "fr/artistes/ERO",
    base_url + "fr/",
    base_url + "fr/contactez-nous/",
    base_url + "fr/expositions/",
    base_url + "fr/shop/",
    base_url + "fr/a-propos/",
    base_url + "fr/mentions-legales/",
    base_url + "fr/visite-virtuelle/",
    base_url + "fr/artistes/",
    base_url + "fr/artistes/iz-the-wiz/",
    base_url + "fr/artistes/john-fekner/",
    base_url + "fr/artistes/kenny-scharf/",
    base_url + "fr/artistes/kool-koor/",
    base_url + "fr/artistes/lady-pink/",
    base_url + "fr/artistes/lee-quinones/",
    base_url + "fr/artistes/niels-shoe-meulman/",
    base_url + "fr/artistes/noc/",
    base_url + "fr/artistes/phase-2/",
    base_url + "fr/artistes/rammellzee/",
    base_url + "fr/artistes/richard-hambleton/",
    base_url + "fr/artistes/seen/",
    base_url + "fr/artistes/sharp/",
    base_url + "fr/artistes/shepard-fairey",
    base_url + "fr/artistes/shepard-fairey/",
    base_url + "fr/artistes/toxic/",
    base_url + "fr/artistes/zephyr/",
    base_url + "fr/artistes/aone/",
    base_url + "fr/artistes/blade/",
    base_url + "fr/artistes/crash/",
    base_url + "fr/artistes/daze/",
    base_url + "fr/artistes/martin-wong",
    base_url + "fr/artistes/martin-wong/",
    base_url + "fr/artistes/jammie-holmes",
    base_url + "fr/artistes/jammie-holmes/",
    base_url + "fr/artistes/delta-2/",
    base_url + "fr/artistes/dondi-white/",
    base_url + "fr/artistes/eddie-martinez/",
    base_url + "fr/artistes/gérard-zlotykamien",
    base_url + "fr/artistes/gérard-zlotykamien/",
    base_url + "fr/artistes/ERO/",
    base_url + "fr/artistes/fab-5/",
    base_url + "fr/artistes/futura-2000/",
    base_url + "fr/artistes/invader/",
    base_url + "fr/artistes/invader",
    base_url + "fr/artistes/herve-di-rosa/",
    base_url + "fr/artistes/herve-di-rosa",
    base_url + "fr/artistes/bando/",
    base_url + "fr/artistes/bill-blast/",
    base_url + "fr/artistes/chaz-bojorquez/",
    base_url + "fr/artistes/daniel-arsham/",
    base_url + "fr/artistes/kase2",
    base_url + "fr/artistes/kase2/",
    base_url + "fr/artistes/LAII",
    base_url + "fr/artistes/LAII/",
    base_url + "fr/artistes/peter-saul",
    base_url + "fr/artistes/peter-saul/",
    base_url + "fr/presse",
    base_url + "fr/presse/",
    base_url + "fr/expositions/aone",
    base_url + "fr/expositions/aone/",
    base_url + "fr/expositions/daze",
    base_url + "fr/expositions/daze/",
    base_url + "fr/expositions/rick-prol",
    base_url + "fr/expositions/rick-prol/",
    base_url + "fr/expositions/robert-nava",
    base_url + "fr/expositions/robert-nava/",
    base_url + "fr/expositions/jordy-kerwick",
    base_url + "fr/expositions/jordy-kerwick/",
    base_url + "fr/expositions/jose-parla",
    base_url + "fr/expositions/jose-parla/",
    base_url + "fr/expositions/kaws/",
    base_url + "fr/expositions/kaws",
    base_url + "fr/expositions/todd-james",
    base_url + "fr/expositions/todd-james/",
    base_url + "fr/expositions/t-kid",
    base_url + "fr/expositions/t-kid/",
    base_url + "fr/expositions/dondi-white",
    base_url + "fr/expositions/dondi-white/",
    base_url + "fr/expositions/kool-koor",
    base_url + "fr/expositions/kool-koor/",
    base_url + "fr/expositions/niels-shoe-meulman",
    base_url + "fr/expositions/niels-shoe-meulman/",
    base_url + "fr/expositions/futura-2000",
    base_url + "fr/expositions/futura-2000/",
    base_url + "fr/expositions/nos-fantomes",
    base_url + "fr/expositions/nos-fantomes/",

  ];

  const en_urls = [
    base_url + "en",
    base_url + "en/contact-us",
    base_url + "en/exhibitions",
    base_url + "en/shop",
    base_url + "en/about-us",
    base_url + "en/legal-mentions",
    base_url + "en/virtual-visit",
    base_url + "en/artists",
    base_url + "en/artists/iz-the-wiz",
    base_url + "en/artists/john-fekner",
    base_url + "en/artists/kenny-scharf",
    base_url + "en/artists/kool-koor",
    base_url + "en/artists/lady-pink",
    base_url + "en/artists/lee-quinones",
    base_url + "en/artists/niels-shoe-meulman",
    base_url + "en/artists/noc",
    base_url + "en/artists/phase-2",
    base_url + "en/artists/rammellzee",
    base_url + "en/artists/richard-hambleton",
    base_url + "en/artists/seen",
    base_url + "en/artists/quik",
    base_url + "en/artists/quik/",
    base_url + "en/artists/shepard-fairey",
    base_url + "en/artists/shepard-fairey/",
    base_url + "en/artists/rick-prol",
    base_url + "en/artists/rick-prol/",
    base_url + "en/artists/sharp",
    base_url + "en/artists/toxic",
    base_url + "en/artists/zephyr",
    base_url + "en/artists/aone",
    base_url + "en/artists/blade",
    base_url + "en/artists/crash",
    base_url + "en/artists/daze",
    base_url + "en/artists/LAII",
    base_url + "en/artists/LAII/",
    base_url + "en/artists/robert-nava",
    base_url + "en/artists/robert-nava/",
    base_url + "en/artists/delta-2",
    base_url + "en/artists/dondi-white",
    base_url + "en/artists/eddie-martinez",
    base_url + "en/artists/fab-5",
    base_url + "en/artists/kaws",
    base_url + "en/artists/kaws/",
    base_url + "en/artists/futura-2000",
    base_url + "en/artists/bando",
    base_url + "en/artists/bill-blast",
    base_url + "en/artists/peter-saul",
    base_url + "en/artists/peter-saul/",
    base_url + "en/artists/chaz-bojorquez",
    base_url + "en/artists/daniel-arsham",
    base_url + "en/artists/henry-chalfant",
    base_url + "en/artists/henry-chalfant/",
    base_url + "en/artists/kase2",
    base_url + "en/artists/kase2/",
    base_url + "en/artists/t-kid",
    base_url + "en/artists/t-kid/",
    base_url + "en/artists/todd-james",
    base_url + "en/artists/todd-james/",
    base_url + "en/",
    base_url + "en/contact-us/",
    base_url + "en/exhibitions/",
    base_url + "en/shop/",
    base_url + "en/about-us/",
    base_url + "en/legal-mentions/",
    base_url + "en/virtual-visit/",
    base_url + "en/artists/",
    base_url + "en/artists/iz-the-wiz/",
    base_url + "en/artists/jammie-holmes",
    base_url + "en/artists/jammie-holmes/",
    base_url + "en/artists/john-fekner/",
    base_url + "en/artists/kenny-scharf/",
    base_url + "en/artists/kool-koor/",
    base_url + "en/artists/lady-pink/",
    base_url + "en/artists/lee-quinones/",
    base_url + "en/artists/niels-shoe-meulman/",
    base_url + "en/artists/noc/",
    base_url + "en/artists/jose-parla",
    base_url + "en/artists/jose-parla/",
    base_url + "en/artists/phase-2/",
    base_url + "en/artists/rammellzee/",
    base_url + "en/artists/richard-hambleton/",
    base_url + "en/artists/herve-di-rosa",
    base_url + "en/artists/herve-di-rosa/",
    base_url + "en/artists/invader/",
    base_url + "en/artists/invader",
    base_url + "en/artists/seen/",
    base_url + "en/artists/sharp/",
    base_url + "en/artists/toxic/",
    base_url + "en/artists/zephyr/",
    base_url + "en/artists/aone/",
    base_url + "en/artists/blade/",
    base_url + "en/artists/eddie-martinez/",
    base_url + "en/artists/ERO/",
    base_url + "en/artists/ERO",
    base_url + "en/artists/martin-wong",
    base_url + "en/artists/martin-wong/",
    base_url + "en/artists/gérard-zlotykamien",
    base_url + "en/artists/gérard-zlotykamien/",
    base_url + "en/artists/crash/",
    base_url + "en/artists/daze/",
    base_url + "en/artists/delta-2/",
    base_url + "en/artists/dondi-white/",
    base_url + "en/artists/fab-5/",
    base_url + "en/artists/futura-2000/",
    base_url + "en/artists/bando/",
    base_url + "en/artists/bill-blast/",
    base_url + "en/artists/chaz-bojorquez/",
    base_url + "en/artists/daniel-arsham/",
    base_url + "en/artists/jordy-kerwick",
    base_url + "en/artists/jordy-kerwick/",
    base_url + "en/press",
    base_url + "en/press/",
    base_url + "en/exhibitions/aone",
    base_url + "en/exhibitions/aone/",
    base_url + "en/exhibitions/daze",
    base_url + "en/exhibitions/daze/",
    base_url + "en/exhibitions/dondi-white",
    base_url + "en/exhibitions/dondi-white/",
    base_url + "en/exhibitions/kool-koor",
    base_url + "en/exhibitions/kool-koor/",
    base_url + "en/exhibitions/niels-shoe-meulman",
    base_url + "en/exhibitions/niels-shoe-meulman/",
    base_url + "en/exhibitions/futura-2000",
    base_url + "en/exhibitions/futura-2000/",
    base_url + "en/exhibitions/nos-fantomes",
    base_url + "en/exhibitions/nos-fantomes/",
  ];

  const currentURL =
    typeof window !== "undefined" ? window.location.href : null;
    var englishUrl = "/en/#top";
    var frenchUrl = "/fr/#top";

  const search_in_fr = () => {
    fr_urls.map((fr_url, j = 0) => {
      if (fr_url === currentURL) {
        englishUrl = en_urls[j];
        frenchUrl = currentURL;
      }
      j = j + 1;
      return null; //added
    });
  };

  const search_in_en = () => {
    en_urls.map((en_url, j = 0) => {
      if (en_url === currentURL) {
        frenchUrl = fr_urls[j];
        englishUrl = currentURL;
      }
      j = j + 1;
      return null; //added
    });
  };

  console.log(currentURL);

  search_in_en();
  search_in_fr();

  return (

    <div>
    <div className={NavbarWrapper}>
      <a className={WhiteTextLink} href="/fr/#top">
        <h2 className={TitleWrapper}>GHOST GALERIE</h2>
      </a>
        <div className={Container}>
          <a href="/fr/">Accueil</a>
          <a href="/fr/artistes">Artistes</a>
          <a href="/fr/expositions">Expositions</a>
          <button style={{margin: "0 15px"}}
          onMouseEnter={() => setnavB(true)}
          >
            Histoire
          </button>
          <a href="/fr-projects">Ghost project</a>
          <a href="/fr/a-propos">À propos</a>
          <a href="/fr/contactez-nous">Contact</a>
          {/* <a href="/fr/shop">Shop</a> */}
          <div className={dropdown}>
            <button className={dropbtn}>
              {/* Langue */}
              {/* <img className={DropdownImageWrapper} src={Dropdown} alt=""/> */}
              <img className={FlagImageWrapper} src={EnglishFlag} alt=""/>
            </button>
            <div className={dropdownContent}>
              <a href={frenchUrl}>
                <img className={FlagImageWrapper} src={FrenchFlag} alt=""/>
                Français
              </a>
              <a href={englishUrl}>
                <img className={FlagImageWrapper} src={EnglishFlag} alt=""/>
                English    
              </a>
            </div>
          </div>
        </div>
        <BurgerMenu
          onClick={() => setDisplayBurger(!displayBurger)}
          display={displayBurger}
          setDisplay={setDisplayBurger}
        >
          <a href="/fr/">
            Accueil
          </a>
          <a href="/fr/artistes">
            Artistes
          </a>
          <a href="/fr/expositions">
            Expositions
          </a>
          <div className={dropdown}>
            <button className={dropbtn} style={{fontSize: "14px"}}>
              Histoire
              {/* <img className={DropdownImageWrapper} src={Dropdown} alt=""/> */}
              <div className={dropdownContent}>
              <a style={{padding: "20px"}} href="/fr/histoire-graffiti">
                Graffiti & post - graffiti
                
              </a>
              <a style={{padding: "20px"}} href="/fr/histoire-east">
                East Village
              </a>
              <a style={{padding: "20px"}} href="/fr/histoire-glossaire">
                Glossaire   
              </a>
            </div>
            </button>
           
          </div>
          {/* <a href="/fr/visite-virtuelle">
            Visite virtuelle
          </a> */}
          
          <a href="/fr-projects/">
            Ghost project
          </a>
          <a href="/fr/a-propos">
            À propos
          </a>
          <a href="/fr/contactez-nous">
            Contact
          </a>
          <div className={dropdown}>
            <button className={dropbtn}>
              {/* Langue */}
              {/* <img className={DropdownImageWrapper} src={Dropdown} alt=""/> */}
              <img className={FlagImageWrapper} src={EnglishFlag} alt=""/>
            </button>
            <div className={dropdownContent}>
              <a href={frenchUrl}>
                <img className={FlagImageWrapper} src={FrenchFlag} alt=""/>
                Français
              </a>
              <a href={englishUrl}>
                <img className={FlagImageWrapper} src={EnglishFlag} alt=""/>
                English    
              </a>
            </div>
          </div>
          {/* <a href="/fr/shop">
            Shop
          </a> */}
        </BurgerMenu>
    </div>
    {navB &&  <div className={SecondBar}
    onMouseEnter={() => setnavB(true)}
    onMouseLeave={() => setnavB(false)}
    >
    <a style={{padding: "20px"}} href="/fr/histoire-graffiti">
        Graffiti & post - graffiti
        
      </a>
      <a style={{padding: "20px"}} href="/fr/histoire-east">
        East Village    
      </a>
      <a style={{padding: "20px"}} href="/fr/histoire-glossaire">
        Glossaire   
      </a>
    </div>}
    </div>
  );
};

Navbar.propTypes = {
  width: number.isRequired,
};

Navbar.defaultProps = {};

export default Navbar;
