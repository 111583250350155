// extracted by mini-css-extract-plugin
export var Addresses = "Footer-module--Addresses--WphDS";
export var AideWrapper = "Footer-module--AideWrapper--ymfa9";
export var CopyrightWrapper = "Footer-module--CopyrightWrapper--l+-+R";
export var FollowWrap = "Footer-module--FollowWrap--asvL+";
export var FooterResp = "Footer-module--FooterResp--RNdGo";
export var FooterWrapper = "Footer-module--FooterWrapper--3-So8";
export var FourthResp = "Footer-module--FourthResp--z8PQf";
export var HorairesTextWrapper = "Footer-module--HorairesTextWrapper--OVtXZ";
export var HorairesWrapper = "Footer-module--HorairesWrapper--rp0Qe";
export var Icon = "Footer-module--Icon--6GX9q";
export var LineWrapper = "Footer-module--LineWrapper--9GnlB";
export var LinkWrapper = "Footer-module--LinkWrapper--nlD9G";
export var LogoLinkWrapper = "Footer-module--LogoLinkWrapper--4-AoA";
export var LogoWrapper = "Footer-module--LogoWrapper--dc8ip";
export var MuseumInfosWrapper = "Footer-module--MuseumInfosWrapper--ZPnrt";
export var NetworksLinkWrapper = "Footer-module--NetworksLinkWrapper--Nl7i2";
export var NetworksWrapper = "Footer-module--NetworksWrapper--PkioZ";
export var PaddingMobile = "Footer-module--PaddingMobile--ROskH";
export var Spacer = "Footer-module--Spacer--mbH0R";
export var ThirdResp = "Footer-module--ThirdResp--0ZOwI";
export var Wrapper = "Footer-module--Wrapper--Ywmzc";